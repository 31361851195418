import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const PatientenportalLiveUSBPage = () => {
  return (
    <Layout pageTitle="USBPatientenportalLive" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Breaking News - 26. Januar 2024</p> 
                <h1>Universitätsspital Basel nimmt Patientenportal von the i-engineers in Betrieb</h1>
               
                <p className="text-medium">
                Das Universitätsspital Basel (USB) hat Mitte Dezember 2023 die Patientenportallösung von the i-engineers erfolgreich in Betrieb genommen und sieht bereits in den ersten Wochen eine hohe Nutzungsrate und Akzeptanz bei den Patienten, Patientinnen und der Belegschaft. </p>

              
                  </div>


                  <div className={col12}>
                <h3>Das USB und die Basis für Mehrwertdienste</h3>

                  <p>Das Universitätsspital Basel (USB) ist eines von fünf universitären Spitälern der Schweiz und das größte Gesundheitszentrum der Nordwestschweiz.</p>
                  <p>Im USB hat man schon vor Jahren erkannt, dass man für erfolgreiche Digitalisierung und Mehrwertdienste eine starke Basis benötigt, nämlich ein zentrales, universelles Data Repository (CDR) und eine Interoperabilitätsplattform (IOP) in Kombination mit einer Workflow Engine. Das USB nutzt dafür die health-engine von the i-engineers und hat auf dieser Basis bereits eine Vielzahl von innovativen Lösungen und damit Mehrwerte für das Haus, das Personal und die Patienten und Patientinnen geschaffen.</p>
                </div>

              <div className={col12}>
              <h3>Das Patientenportal</h3>
              <p>Die Vorteile einer Patientenportallösung lagen für das USB schon früh auf der Hand und man hat sich die Zeit für eine ausgiebige Marktsondierung genommen. Dass man dazu auch mit den the-i-engineers sprach, lag für Marc Strasser, CIO vom Universitätsspital Basel, auf der Hand:</p>
                
                <h3 style={{ marginBottom: 4,  }} className="italic">«Mit the i-engineers haben wir einen langjährigen, verlässlichen Partner, der mit dem technischen Konzept seiner Porttallösung in Verbindung mit den bereits umgesetzten Referenzprojekten gleich überzeugen konnte.»</h3>
                  <div style={{ fontSize: 15,  marginBottom: 30}} className="italic">- Marc Strasser, CIO vom Universitätsspital Basel</div>

              </div>

              <div className={col12}>
              <h3>Die Implementierung</h3>
              <p>Im September 2023 folgte der Zuschlag für the i-engineers und man startete in eine 3- monatige Test- und Implementierungsphase, in der die Lösung auf die Bedürfnisse und das CI des USB angepasst wurden. Bereits Mitte Dezember 2023 startete die Inbetriebnahme und die ersten Patienten und Patientinnen wurden mit der neuen Lösung versorgt.</p>

              </div>

              <div className={col12}>
              <h3>Die Nutzung</h3>
              <p>Schon in der ersten Wochen der Inbetriebnahme haben sich über 8.000 Patienten und Patientinnen auf der neuen Lösung angemeldet und die Resonanz ist durchwegs positiv. Nicht nur die Patienten und Patientinnen profitieren von der Lösung, sondern auch das Haus. Neben den prozessualen und informationstechnischen Vorteilen der Lösung, freut der Aspekt der Kosteneinsparung besonders Martin Gerber, CFO des USB:</p>
              <h3 style={{ marginBottom: 4,  }} className="italic">«Die Kosten- und Zeitersparnis durch den elektronischen Rechnungsversand via Portal, anstelle des bisherigen Postversands, ist immens. Damit finanziert sich die Investition in die Lösung fast von alleine. Und nebenbei wird durch den reduzierten Papierverbrauch auch die Umwelt geschont.»</h3>
                  <div style={{ fontSize: 15,  marginBottom: 30}} className="italic">- Martin Gerber, CFO, Universitätsspital Basel</div>
              </div>

              <div className={col12}>
              <h3>Rollout in mehreren Ausbaustufen</h3>
              <p>Das USB hat sich für einen Rollout in mehreren Phasen entschieden. In der ersten Phase, die im Dezember live ging, wurde der Hauptfokus auf das Versenden von Rechnungskopien und die Einsicht in das persönliche Patientendossier gelegt. Das Portal unterstützt dabei vier Sprachen: Deutsch, Französisch, Italienisch und Englisch.</p>
              <p>In den weiteren Phasen werden Funktionalitäten wie Self-Onboarding, Termineinsicht & - buchung, Videosprechstunde, Indoor-Navigation (USB), Wearables, Studien, Formulare und Fragebögen folgen</p>
             <p>Severin Summermatter, CEO von the i-engineers, bestätigt:</p>
              <h3 style={{ marginBottom: 4,  }} className="italic">«Wir sehen in Ausschreibungen zum Thema Patientenportal typischerweise, dass Kunden sehr komplexe Lösungen mit vielen Funktionen und Modulen anfordern. Unsere Empfehlung in diesem Zusammenhang ist, den Rollout in mehrere Phasen aufzuteilen, und dabei pro Phase neue Module und Funktionalitäten aufzuschalten. So wie das USB dies jetzt umsetzt, ist die Wahrscheinlichkeit einer erfolgreichen Implementation, Nutzung und Akzeptanz am größten.»</h3>
                  <div style={{ fontSize: 15,  marginBottom: 30}} className="italic">- Severin Summermatter, CEO von the i-engineers</div>
              </div>

              <div className={col12}>
              <p>Wir bedanken uns beim Universitätsspital Basel für das entgegengebrachte Vertrauen und freuen uns sehr auf den weiteren Ausbau der Patientenportallösung und eine weiterhin gute und erfolgreiche Zusammenarbeit.</p>
              <p>Wenn Sie mehr über das Projekt und das Patientenportal von the i-engineers erfahren möchten, sind wir sehr gerne für Sie da.</p>
              <div className={buttonGroup}><a href="https://www.tie.ch/module/#patientenportal" className="button-outline blue" target="_blank" rel="noreferrer" >Experten kontaktieren</a></div>
            
              </div>

              <div className={col12}>
              <p> Mehr Infos zu unseren <a href="https://www.tie.ch/portale" target="_blank" rel="noreferrer">Portalen </a></p>
              </div>

            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default PatientenportalLiveUSBPage;
